.support-btn {
  z-index: 1;
  position: fixed;
  bottom: 20px;
  left: 20px;
  border-radius: 2rem;
  padding: .5rem 1rem;
  border-width: 0px;
  background-color: #F59E0B;
  font-size: 1.5rem;
  color: white;
}

@media (min-width: 480px) {
  .support-btn {
    z-index: 1;
    position: absolute;
    bottom: 20px;
    left: 20px;
    border-radius: 2rem;
    padding: .5rem 1rem;
    border-width: 0px;
    background-color: #F59E0B;
    font-size: 1.5rem;
    color: white;
  }
}