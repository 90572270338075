a:link .fading-choice {
    color: black;
    text-decoration: none;
}

a:visited .fading-choice {
    color: black;
    text-decoration: none;
}

a:hover .fading-choice {
    color: black;
    text-decoration: underline;
}

a:active .fading-choice {
    color: black;
    text-decoration: underline;
}
